var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mt-2"},[_c('b-card-title',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Inutilizar Notas")]),_c('div',[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.openInutiliza}},[_c('b-icon-plus'),_vm._v("Nova Inutilização")],1),_c('v-date-picker',{attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"form-control",domProps:{"value":`${inputValue.start} - ${inputValue.end}`}},inputEvents.start))])]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])]),(_vm.loading)?_c('div',{staticClass:"text-center my-4"},[_c('b-spinner'),_vm._v(" carregando "),(_vm.loading)?_c('b-skeleton-table',{attrs:{"type":"table"}}):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('b-table',{attrs:{"fields":[
        { key: 'created_at', label: 'Solicitaçao' },
        { key: 'usuario.nome', label: 'Usuário' },
        { key: 'Notas', label: 'Notas' },
        { key: 'empresas', label: 'Empresa(s)' },
        { key: 'opc', label: '' },
      ],"items":_vm.lista,"busy":_vm.loading,"loading":_vm.loading,"hover":""},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.created_at,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(periodo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.data_inicial,"DD/MM/YYYY"))+" á "+_vm._s(_vm._f("moment")(row.item.data_final,"DD/MM/YYYY"))+" ")]}},{key:"cell(opc)",fn:function(row){return [_c('b-button',{attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.openDetail(row.item)}}},[_c('b-icon-eye')],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"info","size":"sm"},on:{"click":function($event){return _vm.baixarXML(row.item.id)}}},[_c('b-icon-download')],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.baixarXML(row.item.id)}}},[_c('b-icon-trash')],1)]}}],null,false,102449859)},[_vm._v(" å ")]):_vm._e()],1),_c('b-modal',{attrs:{"id":"modal-intulizar-xml","hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Inutilizar Notas ")]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"3"}},[_c('b-form-group',{attrs:{"label":"Ambiente"}},[_c('b-radio-group',{attrs:{"options":[
                { text: 'Produção', value: '1' },
                { text: 'Homologação', value: '2' },
              ]},model:{value:(_vm.obj.ambiente),callback:function ($$v) {_vm.$set(_vm.obj, "ambiente", $$v)},expression:"obj.ambiente"}})],1),_c('b-card',[_c('b',[_vm._v("Adicionar Numeração")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","xl":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-group',{staticClass:"mr-2",attrs:{"label":"Início"}},[_c('b-form-input',{attrs:{"type":"number"},on:{"input":function($event){_vm.add.numero_f = _vm.add.numero_i}},model:{value:(_vm.add.numero_i),callback:function ($$v) {_vm.$set(_vm.add, "numero_i", $$v)},expression:"add.numero_i"}})],1),_c('b-form-group',{attrs:{"label":"Fim"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.add.numero_f),callback:function ($$v) {_vm.$set(_vm.add, "numero_f", $$v)},expression:"add.numero_f"}})],1)],1)]),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Série"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.add.serie),callback:function ($$v) {_vm.$set(_vm.add, "serie", $$v)},expression:"add.serie"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Justificativa"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.add.justificativa),callback:function ($$v) {_vm.$set(_vm.add, "justificativa", $$v)},expression:"add.justificativa"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Empresa"}},[_c('b-select',{staticStyle:{"max-width":"500px","display":"block"},attrs:{"options":_vm.empresas.map((x) => ({
                        text: x.nome,
                        value: { cod_empresa: x.cod_empresa, nome: x.nome },
                      }))},model:{value:(_vm.add.empresa),callback:function ($$v) {_vm.$set(_vm.add, "empresa", $$v)},expression:"add.empresa"}})],1)],1)],1),_c('b-button',{attrs:{"variant":"success","block":""},on:{"click":_vm.addNotas}},[_vm._v("Adicionar "),_c('b-icon-chevron-right')],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"9"}},[_c('b-card',[_c('div',[_c('b',[_vm._v("Númeração á inutlizar")]),_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.obj.notas,"fields":[
                  {
                    key: 'numero',
                    label: 'Número',
                  },

                  {
                    key: 'serie',
                    label: 'Série',
                  },
                  {
                    key: 'justificativa',
                    label: 'Justificativa',
                  },
                  {
                    key: 'empresa',
                    label: 'Empresa',
                  },
                  {
                    key: 'status',
                    label: 'Status Inutilização',
                  },
                  {
                    key: 'opc',
                    label: '',
                  },
                ],"hover":"","size":"sm"},scopedSlots:_vm._u([{key:"cell(empresa)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.empresa.nome)+" ")]}},{key:"cell(opc)",fn:function(row){return [(row.item.status == 'Pendente')?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.obj.notas.splice(row.index, 1)}}},[_c('b-icon-trash')],1):_vm._e()]}}])})],1)])],1)],1)],1),_c('div',{staticClass:"my-4"}),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"info","disabled":_vm.solicitando},on:{"click":_vm.salva}},[(_vm.solicitando)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Salvar & Inutilizar")],1),_c('b-button',{attrs:{"variant":"success","disabled":_vm.solicitando},on:{"click":_vm.requestXML}},[(_vm.solicitando)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Salvar")],1),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":_vm.closeInutilizador}},[_vm._v("Cancelar")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }