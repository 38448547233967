<template>
  <div>
    <b-card class="mt-2">
      <b-card-title>
        <div class="d-flex justify-content-between">
          <div>Inutilizar Notas</div>
          <div>
            <b-button
              variant="success"
              @click="openInutiliza"
              ><b-icon-plus />Nova Inutilização</b-button
            >
            <v-date-picker
              v-model="date"
              is-range
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                    :value="`${inputValue.start} - ${inputValue.end}`"
                    v-on="inputEvents.start"
                    class="form-control"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </b-card-title>
      <div
        class="text-center my-4"
        v-if="loading"
      >
        <b-spinner /> carregando

        <b-skeleton-table
          type="table"
          v-if="loading"
        />
      </div>
      <b-table
        v-if="!loading"
        :fields="[
          { key: 'created_at', label: 'Solicitaçao' },
          { key: 'usuario.nome', label: 'Usuário' },
          { key: 'Notas', label: 'Notas' },
          { key: 'empresas', label: 'Empresa(s)' },
          { key: 'opc', label: '' },
        ]"
        :items="lista"
        :busy="loading"
        :loading="loading"
        hover
      >
        <template #cell(created_at)="row">
          {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(periodo)="row">
          {{ row.item.data_inicial | moment("DD/MM/YYYY") }}
          á
          {{ row.item.data_final | moment("DD/MM/YYYY") }}
        </template>
        å
        <template #cell(opc)="row">
          <b-button
            variant="warning"
            size="sm"
            @click="openDetail(row.item)"
          >
            <b-icon-eye />
          </b-button>
          <b-button
            class="ml-1"
            variant="info"
            size="sm"
            @click="baixarXML(row.item.id)"
          >
            <b-icon-download />
          </b-button>
          <b-button
            class="ml-1"
            variant="danger"
            size="sm"
            @click="baixarXML(row.item.id)"
          >
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="modal-intulizar-xml"
      hide-footer
      size="xl"
    >
      <template #modal-title>Inutilizar Notas </template>

      <div>
        <b-row>
          <b-col
            cols="12"
            xl="3"
          >
            <b-form-group label="Ambiente">
              <b-radio-group
                v-model="obj.ambiente"
                :options="[
                  { text: 'Produção', value: '1' },
                  { text: 'Homologação', value: '2' },
                ]"
              />
            </b-form-group>
            <b-card>
              <b>Adicionar Numeração</b>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  xl="12"
                >
                  <div class="d-flex">
                    <b-form-group
                      label="Início"
                      class="mr-2"
                    >
                      <b-form-input
                        v-model="add.numero_i"
                        type="number"
                        @input="add.numero_f = add.numero_i"
                      />
                    </b-form-group>
                    <b-form-group label="Fim">
                      <b-form-input
                        v-model="add.numero_f"
                        type="number"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  xl="12"
                >
                  <b-form-group label="Série">
                    <b-form-input
                      v-model="add.serie"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  xl="12"
                >
                  <b-form-group label="Justificativa">
                    <b-form-input
                      v-model="add.justificativa"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  xl="12"
                >
                  <b-form-group label="Empresa">
                    <b-select
                      style="max-width: 500px; display: block"
                      v-model="add.empresa"
                      :options="
                        empresas.map((x) => ({
                          text: x.nome,
                          value: { cod_empresa: x.cod_empresa, nome: x.nome },
                        }))
                      "
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                variant="success"
                block
                @click="addNotas"
                >Adicionar <b-icon-chevron-right
              /></b-button>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            xl="9"
          >
            <b-card>
              <div>
                <b>Númeração á inutlizar</b>

                <b-table
                  :items="obj.notas"
                  :fields="[
                    {
                      key: 'numero',
                      label: 'Número',
                    },

                    {
                      key: 'serie',
                      label: 'Série',
                    },
                    {
                      key: 'justificativa',
                      label: 'Justificativa',
                    },
                    {
                      key: 'empresa',
                      label: 'Empresa',
                    },
                    {
                      key: 'status',
                      label: 'Status Inutilização',
                    },
                    {
                      key: 'opc',
                      label: '',
                    },
                  ]"
                  hover
                  size="sm"
                  style="font-size: 10px"
                >
                  <template #cell(empresa)="row">
                    {{ row.item.empresa.nome }}
                  </template>
                  <template #cell(opc)="row">
                    <b-button
                      v-if="row.item.status == 'Pendente'"
                      variant="danger"
                      size="sm"
                      @click="obj.notas.splice(row.index, 1)"
                    >
                      <b-icon-trash />
                    </b-button>
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="my-4"></div>

      <div class="text-right">
        <b-button
          variant="info"
          @click="salva"
          :disabled="solicitando"
          class="mr-2"
        >
          <b-spinner
            small
            v-if="solicitando"
          />
          Salvar & Inutilizar</b-button
        >
        <b-button
          variant="success"
          @click="requestXML"
          :disabled="solicitando"
        >
          <b-spinner
            small
            v-if="solicitando"
          />
          Salvar</b-button
        >
        <b-button
          variant="secondary"
          class="ml-2"
          @click="closeInutilizador"
          >Cancelar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// import SelectEmpresaCaixa from "../../components/common/SelectEmpresaCaixa.vue";
import moment from "moment";
import FiscalLib from "../../libs/FiscalLib";
export default {
  components: {
    // SelectEmpresaCaixa,
  },
  props: {},
  mounted() {
    this.empresas = [
      this.$store.state.empresa_ativa,
      ...this.$store.state.empresa_ativa.empresas,
    ];
    this.loadItems();
  },
  data() {
    return {
      date: {
        start: moment().startOf("year").format("YYYY-MM-DD 00:00:00"),
        end: moment().endOf("year").format("YYYY-MM-DD 23:59:59"),
      },
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        end: moment().endOf("month").format("YYYY-MM-DD 23:59:59"),
      },
      empresas: [],
      obj: {
        ambiente: "1",

        notas: [],
      },
      add: {
        numero_i: "",
        numero_f: "",
        serie: "",
        justificativa: "",
        empresa: null,
      },
      solicitando: false,
      lista: [],
      loading: true,
      objEdit: false,
    };
  },
  watch: {
    range(val) {
      this.obj.data_inicial = moment(val.start).format("YYYY-MM-DD");
      this.obj.data_final = moment(val.end).format("YYYY-MM-DD");
    },
    date() {
      this.loadItems();
    },
  },
  computed: {},
  methods: {
    addNotas() {
      let errs = [];
      if (!this.add.empresa) {
        errs.push("Selecione a empresa");
      }
      if (!this.add.justificativa || this.add.justificativa.trim() == "") {
        errs.push("Informe a justificativa");
      }
      if (!this.add.numero_i || !this.add.numero_f) {
        errs.push("Informe a numeração");
      } else if (this.add.numero_f < this.add.numero_i) {
        errs.push("Número final deve ser maior que o inicial");
      }
      if (!this.add.serie) {
        errs.push("Informe a série");
      }

      if (errs.length) {
        this.$swal({
          title: "Atenção",
          html: errs.join("<hr/>"),
          icon: "warning",
        });
        return;
      }
      let jatem = [];

      for (let i = this.add.numero_i; i <= this.add.numero_f; i++) {
        if (
          this.obj.notas.find(
            (x) =>
              x.numero == i &&
              x.serie == this.add.serie &&
              x.empresa.cod_empresa == this.add.empresa.cod_empresa
          )
        ) {
          jatem.push(i);
        } else {
          this.obj.notas.push({
            numero: i,
            serie: this.add.serie,
            justificativa: this.add.justificativa,
            empresa: this.add.empresa,
            status: "Pendente",
            mensagem: "",
          });
        }
      }
      if (jatem && jatem.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `Os números ${jatem.join(
            ", "
          )} já foram adicionados anteriormente`,
          icon: "warning",
        });
      }
      //clear add
      this.add = {
        numero_i: "",
        numero_f: "",
        serie: "",
        justificativa: "",
        empresa: null,
      };
    },
    async loadItems() {
      this.loading = true;
      await this.$nextTick();
      this.lista = [];

      try {
        let res = await FiscalLib.getInutilizacao({ date: this.date });
        console.log("res", res);
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.error("error", error);
      }

      this.loading = false;
    },
    openInutiliza() {
      this.$bvModal.show("modal-intulizar-xml");
    },
    closeInutilizador() {
      this.$bvModal.hide("modal-intulizar-xml");
      // this.$bvModal.hide("modal-detail-xml");
      this.loadItems();
    },

    openDetail(item) {
      this.objEdit = item;
      this.$bvModal.show("modal-detail-xml");
    },
    async salvar(executar = false) {
      let errs = [];
      if (!this.obj.ambiente) {
        errs.push("Selecione o ambiente");
      }
      if (!this.obj.notas || this.obj.notas.length == 0) {
        errs.push("Adicione a numeração");
      }
      if (errs.length) {
        this.$swal({
          title: "Atenção",
          html: errs.join("<hr/>"),
          icon: "warning",
        });
        return;
      }

      this.solicitando = true;
      // let data = {
      //   ambiente: this.obj.ambiente,
      //   notas: this.obj.notas
      // };
      try {
        let res = await FiscalLib.storeInutilizacao(this.obj);
        console.log("res", res);
        if (res && res.success) {
          this.$swal({
            title: "Sucesso",
            text: "Inutilização realizada com sucesso",
            icon: "success",
          });
          if (executar) {
            this.baixarXML(res.data.id);
          }
          this.closeInutilizador();
        } else {
          this.$swal({
            title: "Atenção",
            text: res.message,
            icon: "warning",
          });
        }
      } catch (error) {
        console.error("error", error);
        this.$swal({
          title: "Atenção",
          text: "Erro ao inutilizar notas",
          icon: "error",
        });
      }
      this.solicitando = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
